.ant-table-cell {
  padding: 8px !important;
}
.ant-table-thead .ant-table-cell {
  background-color: #0e355a !important;
  color: #fff !important;
}
#customers {
  /* font-family: Arial, Helvetica, sans-serif; */
  /* border-collapse: collapse; */
  width: 100%;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

/* #customers tr:nth-child(even){background-color: #f2f2f2;} */

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
  background-color: #fff;
  /* color: white; */
}